import React from "react";
import Layout from "../components/layout";
import Hero from "../components/home/hero";
// import Quote from "../components/home/quote";
import WhoWeAre from "../components/home/whoWeAre";
import Benefits from "../components/home/benefits";
// import Security from "../components/home/security";
import SecondaryFooter from "../components/layout/secondaryFooter";
import Industries from "../components/home/industries";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {
  if (!props.data) return null;
  const data = props.data.allPrismicHome.edges[0].node.dataRaw;
  return (
    <>
      <Helmet>
        <title>{data.title[0].text}</title>
        <meta name="description" content={data.meta_description[0].text} />
      </Helmet>
      <Layout>
        <Hero data={data} />
        {/* <Quote data={data.body[0]} /> */}
        <WhoWeAre data={data.body[1]} />
        <Benefits data={data.body[2]} />
        {/* <Security data={data.body[3]} /> */}
        <SecondaryFooter />
        <Industries data={data.body[4]} />
      </Layout>
    </>
  );
};

export default IndexPage;

export const Home = graphql`
  query MyQuery {
    allPrismicHome {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
