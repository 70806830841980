import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Industries({ data }) {
  return (
    <section className="home_industries">
      <div className="container">
        <div className="home_industries_content">
          <RichText render={data.primary.title1} />
          <RichText render={data.primary.description} />
        </div>
        <div className="home_industries_gallery">
          {data.items.map((content, index) => {
            return (
              <div key={index}>
                <img src={content.image.url} alt="industry" />
                <RichText render={content.industry} />
                <div className="under-border"></div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
