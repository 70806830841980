import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function whoWeAre({ data }) {
  return (
    <section className="home_whoWeAre">
      <div className="container">
        <div className="home_whoWeAre-content">
          {" "}
          <RichText render={data.primary.title1} />
          <RichText render={data.primary.definition} />
          <RichText render={data.primary.description} />
        </div>{" "}
      </div>
    </section>
  );
}
