import React from "react";
import { Button } from "@react-md/button";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function hero({ data }) {
  return (
    <section
      className="home_hero"
      style={{
        backgroundImage: `url(${data.banner_bg_image.url})`,
      }}
    >
      <div className="container">
        {/* <h1>
          We make your apps <br />
          <span className="red-bold-text">battle ready</span>
        </h1>
        <p>Securely develop and deliver applications everywhere by efficient security processes and tooling.</p> */}
        <RichText render={data.hero_title} />
        <RichText render={data.hero_description} />
        <Link to="/company/contact">
          <Button themeType="contained" className="btn">
            CONTACT US
          </Button>
        </Link>
      </div>
    </section>
  );
}
